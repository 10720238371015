import React, { useEffect, useState } from 'react';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import gvcTheme from './theme/gvcTheme';
import colorConfig from './theme/colorConfig';
import {
    selectThemeSeed,
} from './store/feature/themeSlice';

const ThemeInjectedApp = ({ children }) => {
    const [computedTheme, setComputedTheme] = useState(
        createTheme({
            palette: { ...gvcTheme.palette },
            typography: gvcTheme.typography,
            spacing: gvcTheme.spacing,
            breakpoints: gvcTheme.breakpoints,
            overrides: gvcTheme.overrides,
            components: gvcTheme.components,
        }),
    );

    const themeSeed = useSelector(selectThemeSeed);
    const location = useLocation();

    useEffect(() => {
        if (themeSeed) {
            let themeConfig;
            if (location.pathname.includes('/vibeslist') || location.pathname.includes('callback')) {
                themeConfig = { ...colorConfig[2] };
            } else {
                themeConfig = { ...colorConfig[themeSeed - 1] };
            }

            const localTheme = responsiveFontSizes(
                createTheme(
                    {
                        palette: { ...gvcTheme.palette, ...themeConfig },
                        typography: gvcTheme.typography,
                        spacing: gvcTheme.spacing,
                        breakpoints: gvcTheme.breakpoints,
                        overrides: gvcTheme.overrides,
                        components: gvcTheme.components,
                    },
                ),
                { factor: 4 },
            );

            setComputedTheme(localTheme);
        }
    }, [themeSeed, location.pathname]);

    return (
        <>
            {computedTheme && (
                <ThemeProvider theme={computedTheme}>
                    {children}
                </ThemeProvider>
            )}
        </>
    );
};

ThemeInjectedApp.propTypes = {
    children: PropTypes.array.isRequired,
};
export default ThemeInjectedApp;
