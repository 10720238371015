// import { createTheme } from '@mui/material/styles';
// import colorConfig from './colorConfig';
// import {
//     selectThemeSeed,
// } from '../store/feature/userSlice';

const gvcTheme = {
    spacing: (factor) => [0, 4, 8, 16, 32, 64][factor],
    breakpoints: {},
    overrides: {},
    presentation_family: 'gvc-fe',
    palette: {
        // Where primary and secondary colors get injected
        tertiary: {},
        quadrinary: {},

        status: {
            main: '#E1B336',
            500: '#E1B336',
            contrastText: '#fff',
        },
        error: {
            main: '#ff0000',
            500: '#ff0000',
            contrastText: '#fff',
            guidedNav: '#f71449',
        },
        success: {
            main: '#00C876',
            900: '#5c9c4d',
            800: '#528a44',
            700: '#47793b',
            600: '#3d6833',
            500: '#66ad55',
            400: '#75b566',
            300: '#85bd77',
            200: '#94c688',
            100: '#d9f5dc',
            contrastText: '#fff',
        },
        common: {
            black: '#000',
            white: '#fff',
            grey: '#aeaeae',
            green: '#6E9930',
            red: '#d90059',
        },
        background: {
            paper: '#fff',
            default: '#fff', // Used by css baseline to set body bg color
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
        navHeight: '52px',
    },
    typography: {
        fontFamily: ['GTWalsheimProLight', 'sans-serif', 'Roboto'].join(','),
        h1: {
            fontFamily: 'Brice',
            fontWeight: 700,
            fontSize: 50,
            letterSpacing: 'initial',
        },
        h2: {
            fontFamily: 'GTWalsheimProMedium',
            fontWeight: 600,
        },
        h5: {
            fontFamily: 'GTWalsheimProMedium',
            fontWeight: 'normal',
        },
        body1: {
            fontFamily: 'GTWalsheimProLight',
            fontWeight: 300,
            fontSize: 23,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                // root: ({ ownerState }) => ({
                //     ...(ownerState.variant === 'contained' && {
                //         textTransform: 'none',
                //         fontFamily: 'GTWalsheimProMedium',
                //         fontSize: 20,
                //         padding: '8px 35px',
                //         borderRadius: '30px',
                //     }),
                root: {
                    textTransform: 'none',
                    fontFamily: 'GTWalsheimProMedium',
                    fontSize: 25,
                    padding: '8px 30px',
                    borderRadius: '30px',
                    boxShadow: 'none',

                },

            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
    },
};

export default gvcTheme;

// export default createTheme({
//     palette: { ...themeConfig.palette, ...colorConfig[randomNum] },
//     typography: themeConfig.typography,
//     spacing: themeConfig.spacing,
//     breakpoints: themeConfig.breakpoints,
//     overrides: themeConfig.overrides,
//     components: themeConfig.components,
// });
