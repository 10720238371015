import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import colorConfig from '../theme/colorConfig';
import {
    selectThemeSeed,
} from '../store/feature/themeSlice';

const LandingPage = () => {
    const themeSeed = useSelector(selectThemeSeed);

    const [discordVibeTxt, setDiscordVibeTxt] = useState('Discord');

    // const navigate = useNavigate();

    return themeSeed && (
        <Box>
            <Box
                component="div"
                style={{
                    height: '100%',
                    width: '100%',

                }}
            >
                <Box
                    component="img"
                    src={colorConfig[themeSeed - 1].image}
                    alt="gvc-guy"
                    sx={{
                        maxWidth: '850px',
                        marginBottom: { xl: '0px', xs: '-1px' },
                        position: 'absolute',
                        left: {
                            xl: '10%', lg: '0%', md: '25%', xs: '0',
                        },
                        right: { xs: 0 },
                        bottom: 0,
                        height: {
                            xl: '95vh', lg: '95vh', md: '64vh', sm: '50%', xs: '54%',
                        },
                        maxHeight: '1348px',
                        zIndex: '-1',
                        transformOrigin: 'center bottom',
                        margin: { xs: 'auto', sm: 'unset' },
                    }}
                />
            </Box>
            <Box
                container
                spacing={2}
                sx={{
                    position: { md: 'absolute', sm: 'relative' },
                    margin: { sm: 'auto' },
                    padding: { sm: '3em', xs: '1.5em 0 0 0' },
                    top: {
                        xl: '25%', lg: '25%', xs: '4%',
                    },
                    left: {
                        xl: '55%', lg: '54%',
                    },

                }}
                id="bingo"
            >
                <Box
                    container
                >
                    <Grid
                        container
                        sx={{
                            margin: 'auto',
                        }}
                    >
                        <Grid
                            id="yeet"
                            item
                            xs={12}
                            sx={{
                                display: { xs: 'flex', sm: 'block' },
                                justifyContent: { xs: 'center' },
                                paddingLeft: { xs: '0px!important' },
                            }}
                        >
                            <Typography
                                variant="h1"
                                color="primary"
                                sx={{
                                    maxWidth: { sm: '700px', xs: '450px' },
                                    textAlign: { xs: 'center', sm: 'left' },
                                    padding: '10px',
                                    boxSizing: 'border-box',
                                }}
                            >
                                Vibetown is currently
                                under construction.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: { xs: 'flex', sm: 'block' },
                                justifyContent: { xs: 'center' },
                                paddingLeft: { xs: '0px!important' },
                                boxSizing: 'border-box',

                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    maxWidth: { sm: '600px', xs: '350px' },
                                    marginTop: '15px',
                                    textAlign: { xs: 'center', sm: 'left' },
                                    padding: '10px',
                                    boxSizing: 'border-box',

                                }}
                            >
                                Good Vibes Club is a collection of 3D art-focused,
                                randomly generated PFPs. Coming 2022.
                            </Typography>
                        </Grid>
                        <Grid
                            id="buttons"
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="flex-start"
                            sx={{
                                marginTop: { sm: '45px', xs: '25px' },
                                marginBottom: { sm: '45px', xs: '25px' },
                                // paddingLeft: '8px',
                                display: { xs: 'none', sm: 'flex' },
                            }}
                            xs={12}
                        >
                            <Grid
                                item
                            >
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    sx={{
                                        width: { sm: '175px', xs: '150px' },
                                        fontSize: { xs: '20px', sm: '25px' },
                                        '&:hover': {
                                            backgroundColor: `${colorConfig[themeSeed - 1].primary.main}`,
                                            color: `${colorConfig[themeSeed - 1].primary.contrastText}`,
                                            boxShadow: 'none',
                                        },
                                    }}
                                    onClick={() => window.open('https://twitter.com/GoodVibesssClub', '_blank', 'noopener,noreferrer')}
                                >
                                    Follow Us
                                </Button>
                            </Grid>
                            <Grid
                                item
                                sx={{ marginLeft: '15px' }}
                            >
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    sx={{
                                        width: { sm: '175px', xs: '160px' },
                                        fontSize: { xs: '20px', sm: '25px' },
                                        '&:hover': {
                                            backgroundColor: `${colorConfig[themeSeed - 1].primary.main}`,
                                            color: `${colorConfig[themeSeed - 1].primary.contrastText}`,
                                            boxShadow: 'none',
                                            width: 'unset',
                                        },
                                    }}
                                    onMouseOver={() => {
                                        setDiscordVibeTxt('Vibes List Only');
                                    }}
                                    onMouseOut={() => {
                                        setDiscordVibeTxt('Discord');
                                    }}
                                >
                                    {discordVibeTxt}
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                display: { xs: 'none' },
                            }}
                        >
                            {/* Powered by  <span style={{ fontFamily: 'GTWalsheimProMedium' }}> SuperRare.</span> */}
                            <Typography variant="body1">
                                Brought to you by <span style={{ fontFamily: 'GTWalsheimProMedium' }}>Toast.</span>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default LandingPage;
