import axios from 'axios';

const checkIfUserExists = async (address) => {
    let response;
    try {
        response = await axios.post('http://localhost:8080/setup/register', { address });
    } catch (error) {
        console.error(`Error reaching backend ${error}`);
    }
    return response?.data;
};

export default checkIfUserExists;
