import React from 'react';
import {
    Box, Typography, ButtonBase,
    Grid,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { ethers } from 'ethers';
import { addAddress, selectAddress, addJWTToken } from '../store/feature/userSlice';
import {
    getUserAddress,
    checkIfUserExists,
    verifySignature,
} from '../utils/auth';

const MaskConnect = () => {
    const dispatch = useDispatch();
    const address = useSelector(selectAddress);

    const logUserIn = async () => {
        const userAddress = await getUserAddress();
        console.log(`userAddress ${userAddress}`);
        const userExists = await checkIfUserExists(userAddress);

        if (userExists.nonce) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            await provider.send('eth_requestAccounts', []); // connects MetaMask
            const signer = provider.getSigner();
            const signature = await signer.signMessage(userExists.nonce);
            // Call backend to login and get jwt
            const { jwt } = await verifySignature(userAddress, signature);
            console.log(jwt);

            // if we get JWT we are good to go
            if (jwt) {
                dispatch(addJWTToken(jwt));
            } else {
                // Error handle failed to login
                console.error('Failed to login');
            }
        }

        console.log(`userExists ${userExists}`);
        if (userAddress) {
            dispatch(addAddress(userAddress));
        }
    };

    const onClickDisconnectWallet = () => {
        dispatch(addAddress(''));
    };

    return (
        <Box>
            {address ? (
                <Box>
                    <Typography
                        variant="body1"
                        sx={{
                            maxWidth: '750px ',
                            padding: '40px 10px',
                        }}
                    >
                        Pack your bags and prepare your snacks, your flight to Vibe Island
                        departs shortly. But first, we need to some additional information
                        to retrieve your Vibes List boarding pass.
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item>
                            <Typography
                                variant="body1"
                                sx={{ fontFamily: 'GTWalsheimProMedium' }}
                            >
                                - Connected: {address.slice(0, 6)} . . . {address.slice(-6)}
                            </Typography>

                        </Grid>
                        <Grid item>
                            <ButtonBase
                                onClick={onClickDisconnectWallet}
                                sx={{
                                    background: 'none',
                                    fontFamily: 'GTWalsheimProMedium',
                                    fontSize: '25px',
                                    color: 'primary.main',
                                    textDecoration: 'underline',
                                }}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{ fontFamily: 'GTWalsheimProMedium' }}
                                >
                                    disconnect
                                </Typography>
                            </ButtonBase>
                        </Grid>
                    </Grid>
                </Box>
            )
                : (
                    <ButtonBase
                        onClick={() => logUserIn()}
                        sx={{
                            background: 'none',
                            fontFamily: 'GTWalsheimProMedium',
                            fontSize: '25px',
                            color: 'primary.main',
                            textDecoration: 'underline',
                        }}
                    >
                        -  Connect your Wallet
                    </ButtonBase>
                )}
        </Box>
    );
};

export default MaskConnect;
