import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
// import colorConfig from '../../theme/colorConfig';

const initialState = {
    address: '',
    discordUserName: '',
    twitterUserName: '',
    discordStateId: uuidv4(),
    jwt: '',
};

export const userSlice = createSlice({
    name: 'userSlice',
    initialState,
    reducers: {
        addAddress: (state, action) => {
            state.address = action.payload;
        },
        addDiscordUserName: (state, action) => {
            console.log(`Action payload ${action.payload}`);
            state.discordUserName = action.payload;
        },
        addTwitterUserName: (state, action) => {
            state.twitterUserName = action.payload;
        },
        discordStateId: (state, action) => {
            state.discordStateId = action.payload;
        },
        cleanState: () => {
            localStorage.removeItem('persist:root');
            return initialState;
        },
        addJWTToken: (state, action) => {
            state.jwt = action.payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    addAddress,
    addDiscordUserName,
    addTwitterUserName,
    cleanState,
    addJWTToken,
} = userSlice.actions;

export const selectAddress = (state) => state.user.address;
export const selectDiscordName = (state) => state.user.discordUserName;
export const selectTwitterName = (state) => state.user.twitterUserName;
export const selectDiscordStateId = (state) => state.user.discordStateId;
export const selectJWT = (state) => state.user.jwt;

export default userSlice.reducer;
