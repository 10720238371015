import { createSlice } from '@reduxjs/toolkit';
import colorConfig from '../../theme/colorConfig';

const initialState = {

    themeSeed: 1,
    nextSeed: 1,
};

export const themeSlice = createSlice({
    name: 'themeSlice',
    initialState,
    reducers: {

        changeThemeSeedForward: (state) => {
            // console.log(`Current ${state.themeSeed} colorConfig en ${colorConfig.length}`);
            if (state.themeSeed < colorConfig.length) {
                state.themeSeed += 1;
            } else {
                state.themeSeed = 1;
            }
            localStorage.setItem('themeSeed', state.themeSeed);
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    changeThemeSeedForward,
} = themeSlice.actions;

export const selectThemeSeed = (state) => state.theme.themeSeed;

export default themeSlice.reducer;
