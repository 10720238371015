import React from 'react';
import {
    Box,
    Typography,
    ButtonBase,
    Grid,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { selectTwitterName, selectDiscordStateId, addTwitterUserName } from '../store/feature/userSlice';

const DiscordConnect = () => {
    const dispatch = useDispatch();
    const twitterName = useSelector(selectTwitterName);
    const discordStateId = useSelector(selectDiscordStateId);
    const oathTwitterURL = 'https://twitter.com/i/oauth2/authorize?response_type=code&client_id=ME9sbGJCR05fandfckQyQ01xMlI6MTpjaQ&redirect_uri=https://7226-2600-387-f-5919-00-4.ngrok.io/twitter/callback/&scope=tweet.read%20users.read%20offline.access&code_challenge_method=plain';

    const connectTwitterOnClick = () => {
        window.open(`${oathTwitterURL}&state=${discordStateId}&code_challenge=${discordStateId}`, '_self');
    };
    const onClickDisconnectTwitter = () => {
        dispatch(addTwitterUserName(''));
    };

    return (
        <Box>
            {twitterName ? (
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography
                            variant="body1"
                            sx={{ fontFamily: 'GTWalsheimProMedium' }}
                        >
                            - Connected: @{twitterName}
                        </Typography>

                    </Grid>
                    <Grid item>
                        <ButtonBase
                            onClick={onClickDisconnectTwitter}
                            sx={{
                                background: 'none',
                                fontFamily: 'GTWalsheimProMedium',
                                fontSize: '25px',
                                color: 'primary.main',
                                textDecoration: 'underline',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ fontFamily: 'GTWalsheimProMedium' }}
                            >
                                disconnect
                            </Typography>
                        </ButtonBase>
                    </Grid>
                </Grid>

            )
                : (
                    <ButtonBase
                        onClick={() => connectTwitterOnClick()}
                        sx={{
                            background: 'none',
                            fontFamily: 'GTWalsheimProMedium',
                            fontSize: '25px',
                            color: 'primary.main',
                            textDecoration: 'underline',
                        }}

                    >
                        - Connect your Twitter
                    </ButtonBase>
                )}
        </Box>
    );
};

export default DiscordConnect;
