import React, { useEffect, useState } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    changeThemeSeedForward,
    selectThemeSeed,
} from './store/feature/themeSlice';
import NavBar from './Components/NavBar';
import LandingPage from './Pages/LandingPage';
import WhatToDo from './Components/WhatToDo';
import TwitterCallBack from './Components/TwitterCallBack';
import DiscordCallBack from './Components/DiscordCallBack';
import ThemeInjectedApp from './ThemeInjectedApp';

const App = () => {
    const dispatch = useDispatch();
    const themeSeed = useSelector(selectThemeSeed);
    const [canRender, setCanRender] = useState(false);

    useEffect(() => {
        dispatch(changeThemeSeedForward());
    }, []);

    useEffect(() => {
        if (themeSeed !== localStorage.getItem(themeSeed)) {
            setCanRender(true);
        }
    }, [themeSeed, setCanRender]);
    return canRender && (
        <>
            <Router>
                <ThemeInjectedApp>
                    <NavBar />
                    <Routes>
                        <Route index element={<LandingPage />} />
                        <Route path="/discord/callback" element={<DiscordCallBack />} />
                        <Route path="/twitter/callback" element={<TwitterCallBack />} />
                        <Route path="/vibeslist" element={<WhatToDo />} />
                    </Routes>
                </ThemeInjectedApp>
            </Router>

        </>
    );
};

export default App;
