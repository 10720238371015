import React, { useEffect } from 'react';
import qs from 'qs';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authenticateTwitter } from '../utils/oath';
import {
    selectDiscordStateId,
    selectAddress,
    addTwitterUserName,
    selectJWT,
    cleanState,
} from '../store/feature/userSlice';

const TwitterCallBack = () => {
    const getQueryParameters = () => {
        const queryParameters = qs.parse(new URL(window.location.href).search.substring(1), { ignoreQueryPrefix: true });
        return queryParameters;
    };
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const discordStateId = useSelector(selectDiscordStateId);
    const address = useSelector(selectAddress);
    const jwt = useSelector(selectJWT);
    useEffect(async () => {
        if (discordStateId) {
            const qParams = await getQueryParameters();
            console.log(`qparams ${qParams}`);
            if (qParams.state) {
                if (qParams.state === discordStateId) {
                    const responseData = await authenticateTwitter(jwt, qParams.code, address, discordStateId);
                    if (responseData?.error) {
                        if (responseData?.error?.status === 403) {
                            console.error(`JWT invalid, relogin ${responseData}`);
                            // eslint-disable-next-line no-alert
                            alert('There was an error, please login and try again!');
                            dispatch(cleanState());
                            navigate('/vibeslist');
                        }
                    } else {
                        dispatch(addTwitterUserName(responseData.twitterUserName));
                        navigate('/vibeslist');
                    }
                } else {
                    console.log(`State did not match! -> stateId = ${qParams.state} && ${discordStateId}`);
                }
            }
        }
    }, [discordStateId]);

    return (
        <></>
    );
};

export default TwitterCallBack;
