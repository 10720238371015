import React from 'react';
import {
    Box,
    Typography,
    ButtonBase,
    Grid,
} from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { selectDiscordName, selectDiscordStateId, addDiscordUserName } from '../store/feature/userSlice';

const DiscordConnect = () => {
    const dispatch = useDispatch();
    const discordName = useSelector(selectDiscordName);
    const discordStateId = useSelector(selectDiscordStateId);

    const oauthDiscordURL = 'https://discord.com/api/oauth2/authorize?client_id=976551337376497744&redirect_uri=https%3A%2F%2F7226-2600-387-f-5919-00-4.ngrok.io%2Fdiscord%2Fcallback%2F&response_type=code&scope=identify';

    const connectDiscordOnClick = () => {
        window.open(`${oauthDiscordURL}&state=${discordStateId}`, '_self');
    };

    const onClickDisconnectDiscord = () => {
        dispatch(addDiscordUserName(''));
    };

    return (

        <Box>
            {discordName ? (
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography
                            variant="body1"
                            sx={{ fontFamily: 'GTWalsheimProMedium' }}
                        >
                            - Connected: {discordName}
                        </Typography>

                    </Grid>
                    <Grid item>
                        <ButtonBase
                            onClick={onClickDisconnectDiscord}
                            sx={{
                                background: 'none',
                                fontFamily: 'GTWalsheimProMedium',
                                fontSize: '25px',
                                color: 'primary.main',
                                textDecoration: 'underline',
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ fontFamily: 'GTWalsheimProMedium' }}
                            >
                                disconnect
                            </Typography>
                        </ButtonBase>
                    </Grid>
                </Grid>
            )
                : (
                    <ButtonBase
                        onClick={() => connectDiscordOnClick()}
                        sx={{
                            background: 'none',
                            fontFamily: 'GTWalsheimProMedium',
                            fontSize: '25px',
                            color: 'primary.main',
                            textDecoration: 'underline',
                        }}

                    >
                        - Connect your Discord
                    </ButtonBase>
                )}
        </Box>

    );
};

export default DiscordConnect;
