import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './feature/userSlice';
import themeReducer from './feature/themeSlice';

const persistConfig = {
    key: 'root',
    storage,
};

const reducer = combineReducers({ user: userReducer, theme: themeReducer });
const persistedReducer = persistReducer(persistConfig, reducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
