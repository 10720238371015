import React from 'react';
import {
    Box,
    Typography,
    Grid,
} from '@mui/material';
import {
    useSelector,
} from 'react-redux';
import {
    selectAddress,
    selectTwitterName,
    selectDiscordName,
} from '../store/feature/userSlice';
import MaskConnect from './MaskConnect';
import DiscordConnect from './DiscordConnect';
import TwitterConnect from './TwitterConnect';

const WhatToDo = () => {
    const address = useSelector(selectAddress);
    const discordName = useSelector(selectDiscordName);
    const twitterName = useSelector(selectTwitterName);

    const detemineButton = () => {
        let content;

        if (!address) {
            content = <MaskConnect />;
        } else if (!discordName) {
            content = (
                <>
                    <MaskConnect />
                    <DiscordConnect />
                </>
            );
        } else {
            content = (
                <>
                    <MaskConnect />
                    <DiscordConnect />
                    <TwitterConnect />
                </>
            );
        }
        return content;
    };
    return (
        <Box>
            <Grid
                container
                column="true"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Grid
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                >
                    <Typography variant="h1" color="primary" align="center">
                        Your journey to <br />
                        Vibetown begins here.
                    </Typography>
                </Grid>
                <Grid
                    item
                    justifyContent="center"
                    alignItems="flex-start"
                    display="flex"
                    direction="column"
                >
                    {detemineButton()}
                </Grid>
                <Grid
                    item
                    xs={12}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                >
                    {address && discordName && twitterName && (
                        <Typography
                            variant="body1"
                            sx={{
                                maxWidth: '750px ',
                                padding: '40px 10px',
                            }}
                        >
                            Sadly it looks like you are not yet eligible to board the Vibe Force One
                            to Vibetown. We are actively working on selecting new members to join
                            us on Vibe Island, and greatly appreciate the continued love and support
                            from the community.
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default WhatToDo;
