import axios from 'axios';

const authenticateDiscord = async (jwt, code, address) => {
    let response;
    try {
        response = await axios.post('http://localhost:8080/auth/discord',
            {
                code,
                address,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` },
            });
    } catch (error) {
        console.error(`Error reaching backend ${error}`);
        return { error: { status: error?.response?.status, statusText: error.response?.statusText } };
    }
    return response?.data;
};

export default authenticateDiscord;
