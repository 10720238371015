import axios from 'axios';

const verifySignature = async (address, signedMessage) => {
    let response;
    try {
        response = await axios.post(
            'http://localhost:8080/setup/login',
            {
                address,
                signedMessage,
            },
        );
    } catch (error) {
        console.error(`Error reaching backend ${error}`);
    }
    return response?.data;
};

export default verifySignature;
