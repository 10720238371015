import axios from 'axios';

const authenticateTwitter = async (jwt, code, address, challenge) => {
    let response;
    try {
        response = await axios.post('http://localhost:8080/auth/twitter',
            {
                code,
                address,
                challenge,
            },
            {
                headers: { Authorization: `Bearer ${jwt}` },
            });
    } catch (error) {
        console.error(`Error reaching backend ${error}`);
        return { error: { status: error?.response?.status, statusText: error.response?.statusText } };
    }

    return response?.data;
};

export default authenticateTwitter;
