const getUserAddress = async () => {
    let address = '';
    // Asking if metamask is already present or not
    if (window?.ethereum) {
        // res[0] for fetching a first wallet
        const accounts = await window?.ethereum.request({ method: 'eth_requestAccounts' });
        console.log(`found accounts ${accounts}`);
        address = accounts[0];
    } else {
        // eslint-disable-next-line no-alert
        alert('install metamask extension!!');
    }
    return address;
};

export default getUserAddress;
