import * as React from 'react';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import Button from '@mui/material/Button';
import {
    useSelector,
    // useDispatch
} from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import gvclogoPath from '../assets/images/gvc-logo-path.svg';
import MobileLogo from '../assets/images/GoodVibesClub_HandWhite_02.svg';
import twitter from '../assets/images/GoodVibesClub_TwitterIcon.svg';
// import discord from '../assets/images/GoodVibesClub_DiscordIcon.svg';
import colorConfig from '../theme/colorConfig';

// import {
//     selectAddress,
//     cleanState,
// } from '../store/feature/userSlice';
import {
    selectThemeSeed,
} from '../store/feature/themeSlice';

const NavBar = () => {
    const themeSeed = useSelector(selectThemeSeed);
    // const address = useSelector(selectAddress);
    const location = useLocation();
    // const dispatch = useDispatch();

    // const buttonClearState = () => {
    //     dispatch(cleanState());
    // };
    return themeSeed && (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                component="nav"
                sx={{
                    backgroundImage: `${colorConfig[location.pathname.includes('/vibeslist') || location.pathname.includes('callback') ? 2 : themeSeed - 1].backgroundImage}`,
                }}
            >
                <Toolbar>
                    <Link to="/">
                        <Box
                            component="img"
                            sx={{
                                height: 27,
                                flexGrow: 1,
                                display: { sm: 'none' },
                                '& img': {
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                },
                            }}
                            src={MobileLogo}
                            alt="Good vibes club logo"
                        />
                    </Link>
                    <Box
                        component="div"
                        sx={{
                            display: { sm: 'none', xs: 'flex' },
                            justifyContent: 'flex-end',
                            width: '100%',

                        }}

                    >
                        <Box
                            component="img"
                            sx={{
                                height: 23,
                                paddingRight: '8px',
                            }}
                            src={twitter}
                            alt="Twitter Logo"
                            onClick={() => window.open('https://twitter.com/GoodVibesssClub', '_blank', 'noopener,noreferrer')}
                        />

                        {/* <Box
                            component="img"
                            sx={{
                                height: 23,
                                padding: '0px 8px',
                            }}
                            src={discord}
                            alt="Discord Logo"
                            onClick={() => window.open('https://twitter.com/GoodVibesssClub', '_blank', 'noopener,noreferrer')}
                        /> */}

                    </Box>
                    <Box
                        component="div"
                        sx={{
                            height: 25,
                            flexGrow: 1,
                            display: { xs: 'none', sm: 'block' },
                        }}

                    >
                        <Link to="/">
                            <Box
                                component="img"
                                sx={{
                                    height: 21,
                                    display: { xs: 'none', sm: 'block' },
                                    '& img': {
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                    },
                                }}
                                src={gvclogoPath}
                                alt="Good vibes club logo"
                            />
                        </Link>
                    </Box>
                    {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {!address
                            ? (
                                <Button sx={{ color: '#fff' }}>
                                    Connect
                                </Button>
                            )

                            : <Button sx={{ color: '#fff' }} onClick={() => buttonClearState()}>Logout </Button>}
                    </Box> */}
                </Toolbar>
            </AppBar>
            <Box component="main" sx={{ p: 3 }}>
                <Toolbar />
            </Box>
        </Box>
    );
};

export default NavBar;
